// import './RenderPdf.css'
import * as dayjs from "dayjs";
import { Request } from "../../../hooks/Request";
// import logotipo from "../../../assets/LogotipoTC.png";
import { Circle } from "../../../components/Animation";
// import { requestAuth } from '../../components/services/RequestService'
import View from "../../../components/GeneratePdf/View";
import Text from "../../../components/GeneratePdf/Text";
import CustomTablePDF from "../../../components/GeneratePdf/CustomTablePDF";
// import GeneratePdf from '../../components/GeneratePdf/GeneratePdf'
import { Page, Document, PDFViewer, Image, Link } from "@react-pdf/renderer";
import { MessageUnsigned } from "../../../components/Forms/FormMessages";
import { validateArray } from "../../../utils/validate";

const RenderPDFClient = (props) => {
  const { company } = props;

  const { data, loading } = Request({
    urlApi: `/company/${company.id}`,
  });
  const { data: dataContacts, loading: loadingContacts } = Request({
    urlApi: `/company/${company.id}/contacts`,
  });
  const { data: dataInChargce, loading: loadingInCharge } = Request({
    urlApi: `/company/${company.id}/inChargeCompany `,
  });
  const { data: dataCompany, loading: loadingCompany } = Request({
    urlApi: `company/${company.id}/details`,
  });


  if (!loading || !loadingContacts || !loadingInCharge || !loadingCompany) {
    return <Circle />;
  }

  if (!data) {
    return null;
  }
  
  function handleDetail(details) {
    // console.log(auxValue);
    const dataJson = details ? JSON.parse(details) : null;
    let listData = [];
    for (var k in dataJson) {
      listData.push({
        key: k,
        value: dataJson[k],
      });
    }

    return (
      <>
        {validateArray(listData)
          ? listData.map((item) => (
              <View border={{ right: true, bottom: true, left: true }}>
                <Text bold minWidth="130px" border={{ right: true }}>
                  {item.key}:
                </Text>
                <Text>{item.value || <MessageUnsigned />}</Text>
              </View>
            ))
          : null}
      </>
    );
  }

  function handleListDetail(detail) {
    const auxList = detail ? JSON.parse(detail) : [];
    return auxList.map((item) => item.value ? <Text>- {item.value}</Text> : null);
  }

  const MyDoc = (
    <Document>
      <Page size="A4" style={{ padding: "20px" }}>
        <View padding="8px" style={{ backgroundColor: "#d38c19" }}></View>
        <View borderContent>
          <View
            width="33%"
            border={{ right: true }}
            justifyContent="center"
            alignItems="center"
            // style={{ backgroundColor: "red" }}
          >
            {/* <Image src={logotipo} style={{ height: "50px" }} /> */}
          </View>
          <View
            width="34%"
            border={{ right: true }}
            justifyContent="center"
            alignItems="center"
            padding="10px"
          >
            <Text textAlign="center" fontSize="11px" bold>
              {data.razonSocial}
            </Text>
          </View>
          <View width="33%" flexDirection="column">
            <View border={{ bottom: true }}>
              <Text minWidth="50%" bold>
                Fecha de creación:
              </Text>
              <Text border={{ left: true }}>
                {dayjs(data.creationDate).format("DD/MM/YYYY HH:mm")}
              </Text>
            </View>
            {/* <View border={{ bottom: true }}>
              <Text minWidth="50%" bold border={{ right: true }}>
                Número Sistema:
              </Text>
              <Text>{data.systemNum}</Text>
            </View> */}
            <View border={{ bottom: true }} alignItems="center">
              <Text minWidth="50%" bold>
                Id de compañía:
              </Text>
              {/* <Text>{data.internNumber}</Text>
               */}
              <Text fontSize="10px" bold border={{ left: true }}>
                {data.interno}
              </Text>
            </View>
            <View>
              <Text minWidth="50%" bold>
                NIT:
              </Text>
              <Text fontSize="10px" bold border={{ left: true }}>
                {data.nit}
              </Text>
            </View>
          </View>
        </View>
        <View
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#052B4C" }}
        >
          <Text color="white" bold>
            I. Datos del Cliente
          </Text>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <Text bold minWidth="130px" border={{ right: true }}>
            Correo Electronico:
          </Text>
          <Text>{data.email}</Text>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <Text bold minWidth="130px" border={{ right: true }}>
            Telefono:
          </Text>
          <Text>{data.phone}</Text>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <Text bold minWidth="130px" border={{ right: true }}>
            Dirección:
          </Text>
          <Text>{data.direccion}</Text>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <View width="50%">
            <Text bold minWidth="130px" border={{ right: true }}>
              Ubicación:
            </Text>
            <Text>
              <Link src={data.gps}>ubicación</Link>
            </Text>
          </View>
          <View minWidth="50%">
            <Text bold border={{ right: true, left: true }}>
              Categoría:
            </Text>
            <Text>{data.categoria}</Text>
          </View>
        </View>
        {handleDetail(data?.details)}
        {validateArray(dataCompany)
          ? dataCompany.map((item) => (
              <View alignItems="center" border={{ right: true, bottom: true, left: true }}>
                <Text bold minWidth="130px" maxWidth="130px" >
                  {item.label}
                </Text>
                <View flexDirection="col" border={{ left: true }}>{handleListDetail(item.value)}</View>
              </View>
            ))
          : null}

        <View
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#052B4C" }}
        >
          <Text color="white" bold>
            II. Archivos de la Compañía
          </Text>
        </View>
        <CustomTablePDF
          fields={[
            {
              label: "Fecha Inicio",
              name: "creationDate",
              type: "date",
              width: "20",
            },
            {
              label: "Documento",
              name: "name",
              width: "50",
            },
            {
              label: "Enlace",
              name: "url",
              type: "link",
              width: "30",
            },
          ]}
          border={{ right: true, bottom: true, left: true }}
          data={data.files}
        />
        <View
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#052B4C" }}
        >
          <Text color="white" bold>
            III. Contactos de la compañía:
          </Text>
        </View>
        <CustomTablePDF
          fields={[
            {
              label: "Fecha Inicio",
              name: "creationDate",
              type: "date",
              width: "20",
            },
            {
              label: "Nombre",
              name: "name",
              width: "50",
            },
            {
              label: "Telefono",
              name: "phone",
              width: "30",
            },
          ]}
          border={{ right: true, bottom: true, left: true }}
          data={dataContacts}
        />
        <View
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#052B4C" }}
        >
          <Text color="white" bold>
            IV. Encargado del Cliente:
          </Text>
        </View>
        <CustomTablePDF
          fields={[
            {
              label: "Fecha Inicio",
              name: "creationDate",
              type: "date",
              width: "20",
            },
            {
              label: "Nombre",
              name: "name",
              width: "10",
            },
            {
              label: "Apellido Paterno",
              name: "fatherLastName",
              width: "20",
            },
            {
              label: "Apellido Materno",
              name: "motherLastName",
              width: "20",
            },
            {
              label: "Telefono",
              name: "cellphoneNumber",
              width: "10",
            },
            {
              label: "Correo",
              name: "email",
              width: "20",
            },
          ]}
          border={{ right: true, bottom: true, left: true }}
          data={dataInChargce}
        />
      </Page>
    </Document>
  );

  return (
    <div>
      <PDFViewer
        style={{
          width: 900,
          height: window.innerHeight - 100,
        }}
        children={MyDoc}
      />
    </div>
  );
};

export default RenderPDFClient;
