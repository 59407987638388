import * as Yup from "yup";
import { toast } from "react-toastify";
import FormDefault from "../../components/Forms/FormDefault";
import { requestAuth } from "../../components/services/RequestService";

const NewProcedure = (props) => {
  const { reload, onClose } = props;

  async function createSubTramite(procedureId, quantity) {
    await requestAuth("post", `/procedure/items`, {
      procedureId: procedureId,
      Quantity: quantity,
    })
      .then(() => {
        reload();
        onClose();
        toast.success("Trámite agregado correctamente!!!");
      })
      .catch(() => {
        toast.error("Se ha producido un error al crear el Proceso");
      });
  }

  return (
    <div>
      <FormDefault
        tittle="Agregar nuevo trámite"
        fields={[
          {
            label: "Compañia",
            name: "CompanyId",
            typeInput: "select",
            urlApi: "/company/getallcompanies",
            value: "id",
            labelOption: "razonSocial",
          },
          {
            label: "Tipo de procedimiento",
            name: "ProcedureTypeId",
            typeInput: "select",
            urlApi: "/procedureType",
            value: "id",
            labelOption: "name",
          },
          {
            label: "Tipo de tramite",
            name: "Category",
            typeInput: "select",
            options: [
              {
                label: "Normal",
                value: "normal",
              },
              {
                label: "Multiple",
                value: "multiple",
              },
            ],
          },
          {
            label: "Cantidad de Subtramites",
            name: "quantity",
          },
        ]}
        initialValues={{
          CompanyId: "",
          ProcedureTypeId: "",
          Category: "normal",
        }}
        validationSchema={Yup.object().shape({
          CompanyId: Yup.string().required("Este campo es obligatorio"),
          ProcedureTypeId: Yup.string().required("Este campo es obligatorio"),
        })}
        onSubmit={async (values) => {
          await requestAuth("post", `/procedure`, values)
            .then((res) => {
              if (values.Category === "multiple") {
                createSubTramite(res.data.id, values.quantity);
              } else {
                reload();
                onClose();
                toast.success("Trámite agregado correctamente!!!");
              }
            })
            .catch(() => {
              toast.error("Se ha producido un error al crear el Proceso");
            });
        }}
        buttonName="Enviar"
      />
    </div>
  );
};

export default NewProcedure;
