import { Document, Image, PDFViewer, Page } from "@react-pdf/renderer";
import React from "react";
import { Circle } from "../Animation";
import { Request } from "../../hooks/Request";
import View from "../GeneratePdf/View";
import Text from "../GeneratePdf/Text";
import dayjs from "dayjs";
import CustomTablePDF from "../GeneratePdf/CustomTablePDF";
import { validateArray } from "../../utils/validate";

export const ExpenseSheetPdf = ({ procedureId }) => {
  const { data, loading } = Request({
    urlApi: `/Item/procedure/${procedureId}/vehicle`,
  });

  return (
    <div>
      {!loading ? (
        <Circle />
      ) : (
        <RenderGenerateSpreadsheet
          items={data.listaItems}
          procedureId={procedureId}
        />
      )}
    </div>
  );
};

const RenderGenerateSpreadsheet = (props) => {
  const { items, procedureId } = props;
  const { data, loading } = Request({
    urlApi: `/Adafalabella/FormData/Procedure/${procedureId}`,
  });

  if (!loading) {
    return <Circle />;
  }
  const MyDoc = (
    <Document>
      {items && validateArray(items) ? (
        items.map((item, index) => {
          if (index + 1 >= 1 && index + 1 <= items.length) {
            return (
              <Page key={index} size="A4" style={{ padding: "20px" }}>
                <Spreadsheet
                  item={item}
                  procedureId={procedureId}
                  data={data}
                />
              </Page>
            );
          } else {
            return null;
          }
        })
      ) : (
        <Page size="A4" style={{ padding: "20px" }}>
          <SpreadsheetSimple procedureId={procedureId} data={data} />
        </Page>
      )}
    </Document>
  );

  return (
    <div>
      <PDFViewer
        style={{
          width: 900,
          height: window.innerHeight - 100,
        }}
        children={MyDoc}
      />
    </div>
  );
};

export const Spreadsheet = ({ item, data }) => {
  return (
    <View flexDirection="column">
      <View
        borderContent
        justifyContent="space-between"
        style={{ marginBottom: "5px" }}
      >
        <View
          width="25%"
          border={{ right: true }}
          justifyContent="center"
          alignItems="center"
          style={{ paddingVertical: "10px" }}
        >
          <Image
            src="https://res.cloudinary.com/joeldes/image/upload/v1712334424/Ada/logo_de_ADA_w4xzco.jpg"
            style={{ height: "65px" }}
          />
        </View>
        <View
          flexDirection="column"
          width="50%"
          border={{ right: true }}
          justifyContent="center"
          alignItems="center"
          padding="0 10px"
        >
          <Text bold fontSize="16px" textAlign="center">
            PLANILLA DE GASTOS
          </Text>
          <Text fontSize="10px" textAlign="center">
            ADAFALABELLA SRL
          </Text>
          <Text fontSize="10px" textAlign="center">
            AGENCIA DESPACHANTE DE ADUANA
          </Text>
          {/* <Text fontSize="10px" textAlign="center">
            Santa Cruz - Bolivia
          </Text> */}
        </View>
        <View width="25%" justifyContent="space-between" alignItems="center">
          <View flexDirection="column">
            <Text fontSize="12px">PLANILLA:</Text>
            <Text fontSize="12px">FECHA:</Text>
          </View>
          <View flexDirection="column" alignItems="flex-end">
            <Text fontSize="12px" bold>
              {item ? item?.factura : ""}
            </Text>
            <Text fontSize="12px">{data.datos.fecha}</Text>
          </View>
        </View>
      </View>
      <View borderContent>
        <View flexDirection="column" width="50%" border={{ right: true }}>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            CONSIGNATARIO
          </Text>
          <Text fontSize="10px" textAlign="center">
            {data?.company ? data.company.consignatorio : ""}
          </Text>
        </View>
        <View flexDirection="column" width="50%">
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            NIT/C.I.
          </Text>
          <Text fontSize="10px" textAlign="center">
            {data?.company ? data.company.nit : ""}
          </Text>
        </View>
      </View>
      <View border={{ right: true, bottom: true, left: true }}>
        <View flexDirection="column" width="34%" border={{ right: true }}>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            MERCADERÍA
          </Text>
          <Text fontSize="10px" textAlign="center">
            {data?.datos ? data.datos.mercaderia : ""}
          </Text>
        </View>
        <View flexDirection="column" width="33%" border={{ right: true }}>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            PROVEEDOR
          </Text>
          <Text fontSize="10px" textAlign="center">
            {data?.datos ? data.datos.proveedor : ""}
          </Text>
        </View>
        <View flexDirection="column" width="33%">
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            FACTURA PROVEEDOR
          </Text>
          <Text fontSize="10px" textAlign="center">
            {data?.datos ? data.datos.facturaProveedor : ""}
          </Text>
        </View>
      </View>
      <View border={{ right: true, bottom: true, left: true }}>
        <View flexDirection="column" width="34%" border={{ right: true }}>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            NUMERO DIM
          </Text>
          <Text fontSize="10px" textAlign="center">
            {item ? item?.numeroDim : ""}
          </Text>
        </View>
        <View flexDirection="column" width="33%" border={{ right: true }}>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            NUMERO DE REFERENCIA
          </Text>
          <Text fontSize="10px" textAlign="center">
            {item ? item?.interno : ""}
          </Text>
        </View>
        <View flexDirection="column" width="33%">
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            NUMERO FACTURA
          </Text>
          <Text fontSize="10px" textAlign="center">
            {item ? item?.factura : ""}
          </Text>
        </View>
      </View>
      <View border={{ right: true, bottom: true, left: true }}>
        <View flexDirection="column" width="31%" border={{ right: true }}>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            ADMINISTRACIÓN ADUANERA
          </Text>
          <Text fontSize="10px" textAlign="center">
            {data?.datos ? data.datos.administracionAduanera : ""}
          </Text>
        </View>
        <View flexDirection="column" width="23%" border={{ right: true }}>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            CARPETA Nº
          </Text>
          <Text fontSize="10px" textAlign="center">
            {item ? item?.carpetaDeDocumentos : ""}
          </Text>
        </View>
        <View flexDirection="column" width="23%" border={{ right: true }}>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            MANIFIESTO
          </Text>
          <Text fontSize="10px" textAlign="center">
            {data?.datos ? data.datos.manifiesto : ""}
          </Text>
        </View>
        <View flexDirection="column" width="23%">
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            RÉGIMEN
          </Text>
          <Text fontSize="10px" textAlign="center">
            {data?.datos ? data.datos.regimen : ""}
          </Text>
        </View>
      </View>
      <View
        style={{ marginBottom: "5px" }}
        border={{ right: true, bottom: true, left: true }}
      >
        <View flexDirection="column" width="20%" border={{ right: true }}>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            BULTOS
          </Text>
          <Text fontSize="10px" textAlign="center">
            {data?.datos ? data.datos.bultos : ""}
          </Text>
        </View>
        <View flexDirection="column" width="20%" border={{ right: true }}>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            PESO
          </Text>
          <Text fontSize="10px" textAlign="center">
            {item ? item?.peso : ""}
          </Text>
        </View>
        <View flexDirection="column" width="20%" border={{ right: true }}>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            VALOR CIF $US.
          </Text>
          <Text fontSize="10px" textAlign="center">
            {item ? item?.cif : ""}
          </Text>
        </View>
        <View flexDirection="column" width="20%" border={{ right: true }}>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            VALOR CIF Bs.
          </Text>
          <Text fontSize="10px" textAlign="center">
            {item ? item?.cifBs : ""}
          </Text>
        </View>
        <View flexDirection="column" width="20%">
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            TIPO DE CAMBIO
          </Text>
          <Text fontSize="10px" textAlign="center">
            {data?.datos ? data.datos.tipoDeCambio : ""}
          </Text>
        </View>
      </View>
      <CustomTablePDF
        fields={[
          {
            label: "CONCEPTO",
            name: "concepto",
            width: "80",
          },
          {
            label: "TOTAL",
            name: "total",
            width: "20",
          },
        ]}
        border={{ right: true, bottom: true, left: true, top: true }}
        data={data.conceptos}
      />
      <View
        border={{ right: true, bottom: true, left: true }}
        style={{ marginBottom: "10px", backgroundColor: "#0c81c4" }}
      >
        <View flexDirection="column" width="80%" border={{ right: true }}>
          <Text
            fontSize="10px"
            textAlign="center"
            color="white"
            border={{ bottom: true }}
          >
            TOTAL PLANILLA
          </Text>
          <Text fontSize="10px" textAlign="center" color="white">
            TOTAL $us.
          </Text>
        </View>
        <View flexDirection="column" width="20%">
          <Text
            fontSize="10px"
            textAlign="start"
            border={{ bottom: true }}
            // style={{ backgroundColor: "#0c81c4" }}
            color="white"
          >
            {data?.datos ? data.datos.totalPlanilla : ""}
          </Text>
          <Text
            fontSize="10px"
            textAlign="start"
            // style={{ backgroundColor: "#0c81c4" }}
            color="white"
          >
            {data?.datos ? data.datos.totalSus : ""}
          </Text>
        </View>
      </View>
      <View
        style={{ marginBottom: "10px", backgroundColor: "#62aed9" }}
        border={{ right: true, bottom: true, left: true, top: true }}
      >
        <View flexDirection="column" width="70%" border={{ right: true }}>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            A CUENTA
          </Text>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            TOTAL PLANILLA
          </Text>
          <Text fontSize="10px" textAlign="center">
            SALDO A FAVOR DE ADAFALABELLA SRL
          </Text>
        </View>
        <View flexDirection="column" width="10%" border={{ right: true }}>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            Bs.
          </Text>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            Bs.
          </Text>
          <Text fontSize="10px" textAlign="center">
            Bs.
          </Text>
        </View>
        <View flexDirection="column" width="20%">
          <Text fontSize="10px" textAlign="start" border={{ bottom: true }}>
            {data?.datos ? data.datos.acuenta : ""}
          </Text>
          <Text fontSize="10px" textAlign="start" border={{ bottom: true }}>
            {data?.datos ? data.datos.totalPlanilla : ""}
          </Text>
          <Text fontSize="10px" textAlign="start">
            {data?.datos ? data.datos.saldoAfavorAdafalabella : ""}
          </Text>
        </View>
      </View>
      <View
        flexDirection="column"
        border={{ right: true, bottom: true, left: true, top: true }}
      >
        <Text fontSize="10px" textAlign="center" bold>
          NOTA
        </Text>
        <Text fontSize="10px" textAlign="center" bold>
          Esta liquidación está sujeta al tipo de cambio, descripción y/o
          documentación recibida. Favor depositar el monto total de la
          cotización a la cuenta : BANCO UNION a la Cuenta Corriente en Bs. Nº
          10000053646095 a nombre de: ADAFALABELLA S.R.L. NIT: 519066027 T.C.:
          6.97
        </Text>
      </View>
    </View>
  );
};

export const SpreadsheetSimple = ({ data }) => {
  return (
    <View flexDirection="column">
      <View
        borderContent
        justifyContent="space-between"
        style={{ marginBottom: "5px" }}
      >
        <View
          width="25%"
          border={{ right: true }}
          justifyContent="center"
          alignItems="center"
          style={{ paddingVertical: "10px" }}
        >
          <Image
            src="https://res.cloudinary.com/joeldes/image/upload/v1712334424/Ada/logo_de_ADA_w4xzco.jpg"
            style={{ height: "65px" }}
          />
        </View>
        <View
          flexDirection="column"
          width="50%"
          border={{ right: true }}
          justifyContent="center"
          alignItems="center"
          padding="0 10px"
        >
          <Text bold fontSize="16px" textAlign="center">
            PLANILLA DE GASTOS
          </Text>
          <Text fontSize="10px" textAlign="center">
            ADAFALABELLA SRL
          </Text>
          <Text fontSize="10px" textAlign="center">
            AGENCIA DESPACHANTE DE ADUANA
          </Text>
          {/* <Text fontSize="10px" textAlign="center">
            Santa Cruz - Bolivia
          </Text> */}
        </View>
        <View width="25%" justifyContent="space-between" alignItems="center">
          <View flexDirection="column">
            <Text fontSize="12px">PLANILLA:</Text>
            <Text fontSize="12px">FECHA:</Text>
          </View>
          <View flexDirection="column" alignItems="flex-end">
            <Text fontSize="12px" bold>
              {data?.datos?.planilla}
            </Text>
            <Text fontSize="12px">{data.datos.fecha}</Text>
          </View>
        </View>
      </View>
      <View borderContent>
        <View flexDirection="column" width="50%" border={{ right: true }}>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            CONSIGNATARIO
          </Text>
          <Text fontSize="10px" textAlign="center">
            {data?.company ? data.company.consignatorio : ""}
          </Text>
        </View>
        <View flexDirection="column" width="50%">
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            NIT/C.I.
          </Text>
          <Text fontSize="10px" textAlign="center">
            {data?.company ? data.company.nit : ""}
          </Text>
        </View>
      </View>
      <View border={{ right: true, bottom: true, left: true }}>
        <View flexDirection="column" width="34%" border={{ right: true }}>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            MERCADERÍA
          </Text>
          <Text fontSize="10px" textAlign="center">
            {data?.datos ? data.datos.mercaderia : ""}
          </Text>
        </View>
        <View flexDirection="column" width="33%" border={{ right: true }}>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            PROVEEDOR
          </Text>
          <Text fontSize="10px" textAlign="center">
            {data?.datos ? data.datos.proveedor : ""}
          </Text>
        </View>
        <View flexDirection="column" width="33%">
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            FACTURA PROVEEDOR
          </Text>
          <Text fontSize="10px" textAlign="center">
            {data?.datos ? data.datos.facturaProveedor : ""}
          </Text>
        </View>
      </View>
      <View border={{ right: true, bottom: true, left: true }}>
        <View flexDirection="column" width="34%" border={{ right: true }}>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            NUMERO DIM
          </Text>
          <Text fontSize="10px" textAlign="center">
            {data?.datos?.numeroDim}
          </Text>
        </View>
        <View flexDirection="column" width="33%" border={{ right: true }}>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            NUMERO DE REFERENCIA
          </Text>
          <Text fontSize="10px" textAlign="center">
            {data?.datos?.numeroDeReferencia}
          </Text>
        </View>
        <View flexDirection="column" width="33%">
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            NUMERO FACTURA
          </Text>
          <Text fontSize="10px" textAlign="center">
            {data.datos?.planilla}
          </Text>
        </View>
      </View>
      <View border={{ right: true, bottom: true, left: true }}>
        <View flexDirection="column" width="31%" border={{ right: true }}>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            ADMINISTRACIÓN ADUANERA
          </Text>
          <Text fontSize="10px" textAlign="center">
            {data?.datos ? data.datos.administracionAduanera : ""}
          </Text>
        </View>
        <View flexDirection="column" width="23%" border={{ right: true }}>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            CARPETA Nº
          </Text>
          <Text fontSize="10px" textAlign="center">
            {data?.datos?.carpetaN}
          </Text>
        </View>
        <View flexDirection="column" width="23%" border={{ right: true }}>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            MANIFIESTO
          </Text>
          <Text fontSize="10px" textAlign="center">
            {data?.datos ? data.datos.manifiesto : ""}
          </Text>
        </View>
        <View flexDirection="column" width="23%">
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            RÉGIMEN
          </Text>
          <Text fontSize="10px" textAlign="center">
            {data?.datos ? data.datos.regimen : ""}
          </Text>
        </View>
      </View>
      <View
        style={{ marginBottom: "5px" }}
        border={{ right: true, bottom: true, left: true }}
      >
        <View flexDirection="column" width="20%" border={{ right: true }}>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            BULTOS
          </Text>
          <Text fontSize="10px" textAlign="center">
            {data?.datos ? data.datos.bultos : ""}
          </Text>
        </View>
        <View flexDirection="column" width="20%" border={{ right: true }}>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            PESO
          </Text>
          <Text fontSize="10px" textAlign="center">
            {data?.datos?.peso}
          </Text>
        </View>
        <View flexDirection="column" width="20%" border={{ right: true }}>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            VALOR CIF $US.
          </Text>
          <Text fontSize="10px" textAlign="center">
            {data?.datos?.valorCifSus}
          </Text>
        </View>
        <View flexDirection="column" width="20%" border={{ right: true }}>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            VALOR CIF Bs.
          </Text>
          <Text fontSize="10px" textAlign="center">
            {data?.datos?.valorCifBs}
          </Text>
        </View>
        <View flexDirection="column" width="20%">
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            TIPO DE CAMBIO
          </Text>
          <Text fontSize="10px" textAlign="center">
            {data?.datos ? data.datos.tipoDeCambio : ""}
          </Text>
        </View>
      </View>
      <CustomTablePDF
        fields={[
          {
            label: "CONCEPTO",
            name: "concepto",
            width: "80",
          },
          {
            label: "TOTAL",
            name: "total",
            width: "20",
          },
        ]}
        border={{ right: true, bottom: true, left: true, top: true }}
        data={data.conceptos}
      />
      <View
        border={{ right: true, bottom: true, left: true }}
        style={{ marginBottom: "10px", backgroundColor: "#0c81c4" }}
      >
        <View flexDirection="column" width="80%" border={{ right: true }}>
          <Text
            fontSize="10px"
            textAlign="center"
            color="white"
            border={{ bottom: true }}
          >
            TOTAL PLANILLA
          </Text>
          <Text fontSize="10px" textAlign="center" color="white">
            TOTAL $us.
          </Text>
        </View>
        <View flexDirection="column" width="20%">
          <Text
            fontSize="10px"
            textAlign="start"
            border={{ bottom: true }}
            // style={{ backgroundColor: "#0c81c4" }}
            color="white"
          >
            {data?.datos ? data.datos.totalPlanilla : ""}
          </Text>
          <Text
            fontSize="10px"
            textAlign="start"
            // style={{ backgroundColor: "#0c81c4" }}
            color="white"
          >
            {data?.datos ? data.datos.totalSus : ""}
          </Text>
        </View>
      </View>
      <View
        style={{ marginBottom: "10px", backgroundColor: "#62aed9" }}
        border={{ right: true, bottom: true, left: true, top: true }}
      >
        <View flexDirection="column" width="70%" border={{ right: true }}>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            A CUENTA
          </Text>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            TOTAL PLANILLA
          </Text>
          <Text fontSize="10px" textAlign="center">
            SALDO A FAVOR DE ADAFALABELLA SRL
          </Text>
        </View>
        <View flexDirection="column" width="10%" border={{ right: true }}>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            Bs.
          </Text>
          <Text fontSize="10px" textAlign="center" border={{ bottom: true }}>
            Bs.
          </Text>
          <Text fontSize="10px" textAlign="center">
            Bs.
          </Text>
        </View>
        <View flexDirection="column" width="20%">
          <Text fontSize="10px" textAlign="start" border={{ bottom: true }}>
            {data?.datos ? data.datos.acuenta : ""}
          </Text>
          <Text fontSize="10px" textAlign="start" border={{ bottom: true }}>
            {data?.datos ? data.datos.totalPlanilla : ""}
          </Text>
          <Text fontSize="10px" textAlign="start">
            {data?.datos ? data.datos.saldoAfavorAdafalabella : ""}
          </Text>
        </View>
      </View>
      <View
        flexDirection="column"
        border={{ right: true, bottom: true, left: true, top: true }}
      >
        <Text fontSize="10px" textAlign="center" bold>
          NOTA
        </Text>
        <Text fontSize="10px" textAlign="center" bold>
          Esta liquidación está sujeta al tipo de cambio, descripción y/o
          documentación recibida. Favor depositar el monto total de la
          cotización a la cuenta : BANCO UNION a la Cuenta Corriente en Bs. Nº
          10000053646095 a nombre de: ADAFALABELLA S.R.L. NIT: 519066027 T.C.:
          6.97
        </Text>
      </View>
    </View>
  );
};
