import RenderPdf from "../../containers/ManegeProcedure/RenderPdf";
import GenerateRoadmap from "../../containers/ManegeProcedure/GenerateRoadmap";
import RenderLiquidaciónProforma from "../../containers/ManegeProcedure/RenderLiquidaciónProforma";
import RenderProforma from "../../containers/ManegeProcedure/RenderProforma";
import { useDialog } from "../../contexts/DialogContext";
import { useModal } from "../../contexts/ModalContext";
import { validateArray } from "../../utils/validate";
import { Tooltip } from "react-tippy";
import ListDocument from "../../components/common/ListDocument";
import ListDocumentVerify from "../../components/common/ListDocumentVerify";
import UploadExpenseSheet from "./UploadExpenseSheet";
import { ExpenseSheetPdf } from "./ExpenseSheetPdf";
import { useParams } from "react-router-dom";
import { H3, Anchor } from "../../components/Text";
import FormCopyFolderFiles from "../../containers/ManegeProcedure/FormCopyFolderFiles";
import FormOrderDocs from "../../containers/ManegeProcedure/FormOrderDocs";

const TableLisDocument = ({ listButton, procedure, setReload }) => {
  const { openDialog, dialogClose } = useDialog();
  const { openModal, onClose } = useModal();
  const { rolId } = useParams();

  function listDocumentPdf(procedure) {
    openDialog(<RenderPdf procedure={procedure} />);
  }
  function generateSettlementProforma(procedure) {
    openDialog(<RenderLiquidaciónProforma procedure={procedure} />);
  }

  function generateProforma(procedure) {
    openDialog(<RenderProforma procedure={procedure} />);
  }

  function generateRoadmap(procedure) {
    openDialog(<GenerateRoadmap procedure={procedure} />);
  }
  function handleDocument(procedure) {
    openModal(<ListDocument procedure={procedure} />);
  }
  function handleDocumentVerify(procedure) {
    openModal(<ListDocumentVerify procedure={procedure} />);
  }

  function uploadExpenseSheet(procedure) {
    openModal(
      <UploadExpenseSheet procedureId={procedure.id} onClose={onClose} />
    );
  }

  function generatePdfExpenseSheet(procedure) {
    openDialog(<ExpenseSheetPdf procedureId={procedure.id} />);
  }

  function handleManageItems(procedure) {
    if (procedure.format === "multiple") {
      var win = window.open(
        `/rol/${rolId}/procedure-type/${procedure.procedureTypeId}/step/1/procedure/${procedure.id}/generate/clone-items`,
        "_blank"
      );
      win.focus();
      openDialog(
        <div className="w-[400px]">
          <H3 className="text-[1.4rem]">El despacho es un tramite multiple</H3>
          <center>
            <Anchor
              className="text-[#264fbe] text-[1.2rem]"
              href={`/rol/${rolId}/procedure-type/${procedure.procedureTypeId}/step/1/procedure/${procedure.id}/generate/clone-items`}
              // download
              target="_blank"
              rel="noopener noreferrer"
            >
              Ir a la pagina de items
            </Anchor>
          </center>
          <br />
        </div>
      );
    } else {
      openDialog(<ExpenseSheetPdf procedureId={procedure.id} />);
    }
  }

  function copyFolders(procedure) {
    openModal(<FormCopyFolderFiles onClose={onClose} setReload={setReload} proc={procedure} />);
  }
  function orderDocs(procedure) {
    openModal(<FormOrderDocs onClose={onClose} setReload={setReload} proc={procedure} />);
  }

  let listGeneralButtons = [
    {
      label: "Generar informe de la recepción de documentos",
      icon: "fas fa-tasks",
      Name: "recepcionDeDocumentos",
      action: listDocumentPdf,
      color: "text-[#1d4ed8]",
    },
    {
      label: "Generar liquidación - proforma",
      icon: "fas fa-coins",
      Name: "proforma",
      action: generateProforma,
      color: "text-[#1d4ed8]",
    },
    {
      label: "Generar hoja de ruta",
      icon: "fas fa-route",
      Name: "hojaDeRuta",
      action: generateRoadmap,
      color: "text-[#1d4ed8]",
    },
    {
      label: "Ver documentos",
      icon: "fa-solid fa-folder",
      Name: "verDocumentos",
      action: handleDocument,
      color: "text-[#1d4ed8]",
    },
    {
      label: "Verificar documentos",
      icon: "fa-solid fa-list-check",
      Name: "verificarDocumentos",
      action: handleDocumentVerify,
      color: "text-[#1d4ed8]",
    },
    {
      label: "Verificar documentos",
      icon: "fa-solid fa-list-check",
      Name: "verificarDocumentos",
      action: handleDocumentVerify,
      color: "text-[#1d4ed8]",
    },
    {
      label: "Subir planilla de gastos",
      icon: "fas fa-upload",
      Name: "expenseSheet",
      action: uploadExpenseSheet,
      color: "text-[#1d4ed8]",
    },
    {
      label: "Generar planilla de gastos",
      icon: "fas fa-print",
      Name: "generateExpenseSheet",
      action: generatePdfExpenseSheet,
      color: "text-[#1d4ed8]",
    },
    {
      label: "Copiar documentos de carpeta",
      icon: "fas fa-folder-closed",
      Name: "copyDocFolders",
      action: copyFolders,
      color: "text-[#1d4ed8]",
    },
    {
      label: "Ordenar documentos",
      icon: "fas fa-list-ol",
      Name: "orderDocs",
      action: orderDocs,
      color: "text-[#1d4ed8]",
    },
    {
      label: "Gestionar items",
      icon: "fas fa-bars",
      Name: "manageItems",
      action: handleManageItems,
      color: "text-[#1d4ed8]",
    },
  ];

  return (
    <>
      {validateArray(listButton)
        ? listButton.map((btn, index) => {
            const button = listGeneralButtons.find(
              (element) => element.Name === btn.Name
            );
            if (button === undefined) {
              return null;
            }
            return (
              <div
                key={index}
                className={`${button.color} text-xl cursor-pointer`}
                onClick={() => button.action(procedure)}
              >
                <Tooltip
                  title={button.label}
                  position="right"
                  trigger="mouseenter"
                >
                  <i className={button.icon + " p-1"}></i>
                </Tooltip>
              </div>
            );
          })
        : null}
    </>
  );
};

export default TableLisDocument;
