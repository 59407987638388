import { toast } from "react-toastify";
import { Request } from "../../hooks/Request";
import { H2, H3 } from "../../components/Text";
import { Button } from "../../components/Buttons";
import Circle from "../../components/Animation/Circle";
import { useModal } from "../../contexts/ModalContext";
import { useDialog } from "../../contexts/DialogContext";
import FormDefault from "../../components/Forms/FormDefault";
import TableGeneral from "../../components/ExcelPages/TableGeneral";
import { requestAuth } from "../../components/services/RequestService";
import { validateArray } from "../../utils/validate";

import { Page, Document, PDFViewer } from "@react-pdf/renderer";
import Text from "../../components/GeneratePdf/Text";
import View from "../../components/GeneratePdf/View";
import UploadExpenseSheet from "../../components/common/UploadExpenseSheet";
import { ExpenseSheetPdf } from "../../components/common/ExpenseSheetPdf";

const CloneItems = (props) => {
  const { procedureId, importExcel, handleDelete } = props;
  const { openModal, onClose } = useModal();
  const { openDialog, dialogClose } = useDialog();
  const { data, loading, call } = Request({
    urlApi: `/Item/procedure/${procedureId}/vehicle`,
  });

  function handleClone() {
    openDialog(
      <FormClone
        reload={call}
        onClose={dialogClose}
        procedureId={procedureId}
      />
    );
  }

  function FormGenerateSpreadsheet() {
    openDialog(
      <FormGenerate
        items={data}
        openDialog={openDialog}
        dialogClose={dialogClose}
      />
    );
  }

  function uploadExpenseSheet() {
    openModal(
      <UploadExpenseSheet procedureId={procedureId} onClose={onClose} />
    );
  }

  function generatePdfExpenseSheet() {
    openDialog(<ExpenseSheetPdf procedureId={procedureId} />);
  }

  if (!loading) {
    return <Circle />;
  }
  return (
    <div>
      <div className="flex justify-between items-center mb-2">
        <H2>Clonar items</H2>
        <div className="flex gap-2 items-center">
          {/* {validateArray(data) && (
            <Button
              position="left"
              className="text-base px-1.5 py-1"
              content="Generar planilla de gastos"
              onClick={FormGenerateSpreadsheet}
            >
              Generar planilla <i className="fas fa-print"></i>
            </Button>
          )} */}
          <Button
            position="left"
            className="text-base px-1.5 py-1"
            content="Clonar items"
            onClick={generatePdfExpenseSheet}
          >
            Generar planilla
          </Button>
          <Button
            position="left"
            className="text-base px-1.5 py-1"
            content="Clonar items"
            onClick={uploadExpenseSheet}
          >
            Extraer de un excel <i className="fas fa-clone"></i>
          </Button>
          {/* <Button
            position="left"
            className="text-base px-1.5 py-1"
            content="Extraer datos de un excel"
            onClick={() =>
              importExcel(call, `/Item/read/procedure/${procedureId}`)
            }
          >
            Extraer de un excel <i className="fas fa-clone"></i>
          </Button> */}
        </div>
      </div>
      <TableGeneral
        addBtn="Adicionar item"
        editBtn="Editar item"
        restHeight={200}
        call={call}
        handleReload={call}
        header={[
          { name: "itemNumber", label: "Nro", hidden: true },
          { name: "interno", label: "NUMERO DE REFERENCIA" },
          { name: "carpetaDeDocumentos", label: "CARPETA Nº" },
          { name: "numeroDim", label: "NRO DIM" },
          // { name: "valorDeRef", label: "NRO DE REFERENCIA" },
          { name: "factura", label: "NRO DE FACTURA" },
          { name: "peso", label: "PESO" },
          { name: "cif", label: "VALOR CIF SUS" },
          { name: "cifBs", label: "VALOR CIF BS" },
          // { name: "tipo", label: "Tipo" },
          // { name: "usoEspecial", label: "Uso especial" },
          // { name: "cilindrada", label: "Cilindrada" },
          // { name: "combustible", label: "Combustible" },
          // { name: "paisDeOrigen", label: "Pais de origen" },
          // { name: "nroDeRuedas", label: "Nro. de ruedas" },
          // { name: "capDeCarga", label: "Cap. de carga" },
          // { name: "valorDeRef", label: "Valor de ref" },
          // { name: "marca", label: "Marca" },
          // { name: "subTipo", label: "Sub tipo" },
          // { name: "gestionFabricacion", label: "Año de fabricación" },
          // { name: "traccion", label: "Tracción" },
          // { name: "frame", label: "Frame" },
          // { name: "transmision", label: "Transmisión" },
          // { name: "gestionModelo", label: "Año modelo" },
          // { name: "nroPuertas", label: "Nro. de puertas" },
          // { name: "plazas", label: "Plazas" },
          // { name: "campero", label: "Campero" },
          {
            name: "action",
            type: "action",
            label: "",
            hidden: true,
            actions: [
              // {
              //   label: 'Clonar',
              //   icon: 'fas fa-copy',
              //   action: (item, reload) =>
              //     handleClone(item, reload, procedureId),
              //   color: 'text-[#1d4ed8]',
              //   bgColor: 'bg-[#1d4fd842]',
              // },
              {
                label: "Eliminar",
                icon: "fas fa-trash",
                action: (item, reload) =>
                  handleDelete(
                    item,
                    reload,
                    "el item",
                    `/procedureSheet/item/${item.id}`
                  ),
                color: "text-red-500",
                bgColor: "bg-red-300",
              },
            ],
          },
        ]}
        data={data.listaItems}
        urlPost={`/Item/procedure/${procedureId}/vehicle`}
        urlPut="/Item/vehicle/"
      />
    </div>
  );
};

const FormGenerate = (props) => {
  const { openDialog, dialogClose, items } = props;

  return (
    <>
      <FormDefault
        tittle="Formulario para generar planilla de gastos"
        fields={[
          {
            label: "Desde",
            name: "From",
            placeholder: "Desde",
          },
          {
            label: "Hasta",
            name: "To",
            placeholder: "Hasta",
          },
        ]}
        initialValues={{
          From: "1",
          To: "1",
        }}
        validationSchema={null}
        onSubmit={(values) => {
          openDialog(
            <RenderGenerateSpreadsheet limit={values} items={items} />
          );
        }}
        buttonName="Generar"
      />
      <br />
    </>
  );
};

const RenderGenerateSpreadsheet = (props) => {
  const { limit, items } = props;
  const MyDoc = (
    <Document>
      {items
        ? items.map((item, index) => {
            if (index + 1 >= limit.From && index + 1 <= limit.To) {
              return (
                <Page key={index} size="A4" style={{ padding: "20px" }}>
                  <Spreadsheet item={item} />
                </Page>
              );
            } else {
              return null;
            }
          })
        : ""}
    </Document>
  );

  return (
    <div>
      <PDFViewer
        style={{
          width: 900,
          height: window.innerHeight - 100,
        }}
        children={MyDoc}
      />
    </div>
  );
};

const Spreadsheet = ({ item }) => {
  // console.log(item)
  // const { data, loading } = Request({
  //   urlApi: `/procedure/${procedure.id || procedure.procedureId}/proform`,
  // })

  // if (!loading) {
  //   return <Circle />
  // }
  return (
    <View flexDirection="column">
      <View>
        <View flexDirection="column" padding="0 0 0 80px" width="75%">
          <Text bold fontSize="16px" textAlign="center">
            BRUSECO S.R.L.
          </Text>
          <Text fontSize="10px" textAlign="center">
            AGENCIA DESPACHANTE DE ADUANA
          </Text>
          <Text fontSize="10px" textAlign="center">
            Cochabamba - Bolivia
          </Text>
          <Text
            bold
            fontSize="12px"
            style={{ textDecoration: "underline" }}
            textAlign="center"
          >
            PLANILLA DE GASTOS
          </Text>
        </View>
        <View flexDirection="column" width="25%">
          <Text fontSize="16px" textAlign="center">
            TRAMITE:
          </Text>
        </View>
      </View>
      <View alignItems="center">
        <View width="25%" flexDirection="column" alignItems="flex-end">
          <Text bold fontSize="10px">
            Nombre del Importador:
          </Text>
          <Text bold fontSize="10px">
            NIT o CI:
          </Text>
          <Text bold fontSize="10px">
            DUI:
          </Text>
          <Text bold fontSize="10px">
            Descripción:
          </Text>
          <Text bold fontSize="10px">
            Cod Bruseco:
          </Text>
        </View>
        <View width="75%" flexDirection="column">
          <Text fontSize="10px">--</Text>
          <Text fontSize="10px">--</Text>
          <Text fontSize="10px">--</Text>
          <Text fontSize="10px">--</Text>
          <View>
            <Text minWidth="230px">--</Text>
            <Text bold fontSize="10px">
              Aduana:
            </Text>
            <Text>--</Text>
          </View>
        </View>
      </View>

      <View>
        <View
          border={{ top: true, right: true, left: true }}
          flexDirection="column"
        >
          <View alignItems="center" border={{ bottom: true }}>
            <View width="75%" flexDirection="column" alignItems="flex-end">
              <Text
                bold
                fontSize="12px"
                style={{ textDecoration: "underline" }}
              >
                GASTOS EFECTUADOS
              </Text>
            </View>
            <View width="25%" flexDirection="column"></View>
          </View>
          <View border={{ bottom: true }}>
            <View
              width="25%"
              flexDirection="column"
              alignItems="flex-end"
              border={{ right: true }}
            >
              <View width="100%" flexDirection="column" alignItems="center">
                <Text
                  bold
                  textAlign="center"
                  style={{ textDecoration: "underline" }}
                >
                  DESCRIPCIÓN
                </Text>
              </View>
              <Text>Gravamen Arancelario</Text>
              <Text>Impuesto al Valor Agregado</Text>
              <Text>Impuesto al Consumo Especifico</Text>
              <Text>Almacenaje</Text>
              <Text>Camara de comercio</Text>
              <Text>Carpeta de documentos</Text>
              <Text>Certificado de Aire Acondicionado</Text>
              <Text>Certificado de Conversión a GNV</Text>
              <Text>Certificación de Emisión de Gases</Text>
              <Text>Certificado de Ibnorca</Text>
              <Text>Formulario 187</Text>
              <Text>Fletes</Text>
              <Text>Gastos de Despacho</Text>
              <Text>Levante de Abandono</Text>
              <Text>Gastos Puerto</Text>
              <Text>Impuesto Global Sidunea</Text>
              <Text>Inspección Técnica</Text>
              <Text>Memoriales</Text>
              <Text>Otros gastos</Text>
              <Text>Seguro</Text>
              <Text>Senasag</Text>
              <Text>Frontera S.A.</Text>
              <Text>Otros</Text>
              <Text>Otros</Text>
            </View>
            <View
              width="25%"
              flexDirection="column"
              alignItems="center"
              border={{ right: true }}
            >
              <Text
                bold
                textAlign="center"
                style={{ textDecoration: "underline" }}
              >
                FACTURA
              </Text>
            </View>
            <View
              width="25%"
              flexDirection="column"
              alignItems="center"
              border={{ right: true }}
            >
              <Text
                bold
                textAlign="center"
                style={{ textDecoration: "underline" }}
              >
                MONEDA
              </Text>
              <Text>Bs.</Text>
              <Text>Bs.</Text>
              <Text>Bs.</Text>
              <Text>Bs.</Text>
              <Text>Bs.</Text>
              <Text>Bs.</Text>
              <Text>Bs.</Text>
              <Text>Bs.</Text>
              <Text>Bs.</Text>
              <Text>Bs.</Text>
              <Text>Bs.</Text>
              <Text>Bs.</Text>
              <Text>Bs.</Text>
              <Text>Bs.</Text>
              <Text>Bs.</Text>
              <Text>Bs.</Text>
              <Text>Bs.</Text>
              <Text>Bs.</Text>
              <Text>Bs.</Text>
              <Text>Bs.</Text>
              <Text>Bs.</Text>
              <Text>Bs.</Text>
              <Text>Bs.</Text>
              <Text>Bs.</Text>
            </View>
            <View width="25%" flexDirection="column" alignItems="center">
              <Text
                bold
                textAlign="center"
                style={{ textDecoration: "underline" }}
              >
                IMPORTE
              </Text>
            </View>
          </View>
        </View>
        <View
          borderContent
          flexDirection="column"
          alignItems="center"
          style={{
            marginLeft: "10px",
            backgroundColor: "#1a1a1a",
            gap: "0px",
          }}
        >
          <Text bold fontSize="10px" color="white" padding="2px 3px">
            S
          </Text>
          <Text bold fontSize="10px" color="white" padding="2px 3px">
            I
          </Text>
          <Text
            bold
            fontSize="10px"
            color="white"
            padding="2px 3px"
            style={{ marginBottom: "10px" }}
          >
            N
          </Text>
          <Text bold fontSize="10px" color="white" padding="2px 3px">
            D
          </Text>
          <Text bold fontSize="10px" color="white" padding="2px 3px">
            E
          </Text>
          <Text bold fontSize="10px" color="white" padding="2px 3px">
            R
          </Text>
          <Text bold fontSize="10px" color="white" padding="2px 3px">
            E
          </Text>
          <Text bold fontSize="10px" color="white" padding="2px 3px">
            C
          </Text>
          <Text bold fontSize="10px" color="white" padding="2px 3px">
            H
          </Text>
          <Text
            bold
            fontSize="10px"
            color="white"
            padding="2px 3px"
            style={{ marginBottom: "10px" }}
          >
            O
          </Text>
          <Text
            bold
            fontSize="10px"
            padding="2px 3px"
            color="white"
            style={{ marginBottom: "10px" }}
          >
            A
          </Text>
          <Text bold fontSize="10px" color="white" padding="2px 3px">
            C
          </Text>
          <Text bold fontSize="10px" color="white" padding="2px 3px">
            R
          </Text>
          <Text bold fontSize="10px" color="white" padding="2px 3px">
            E
          </Text>
          <Text bold fontSize="10px" color="white" padding="2px 3px">
            D
          </Text>
          <Text bold fontSize="10px" color="white" padding="2px 3px">
            I
          </Text>
          <Text bold fontSize="10px" color="white" padding="2px 3px">
            T
          </Text>
          <Text
            bold
            fontSize="10px"
            padding="2px 3px"
            color="white"
            style={{ marginBottom: "10px" }}
          >
            O
          </Text>
          <Text bold fontSize="10px" color="white" padding="2px 3px">
            F
          </Text>
          <Text bold fontSize="10px" color="white" padding="2px 3px">
            I
          </Text>
          <Text bold fontSize="10px" color="white" padding="2px 3px">
            S
          </Text>
          <Text bold fontSize="10px" color="white" padding="2px 3px">
            C
          </Text>
          <Text bold fontSize="10px" color="white" padding="2px 3px">
            A
          </Text>
          <Text bold fontSize="10px" color="white" padding="2px 3px">
            L
          </Text>
        </View>
      </View>

      <Text bold>SON:</Text>
      <Text textAlign="center" bold>
        Cochabamba,
      </Text>
      <Text textAlign="center" bold>
        Oficina, calle 25 de Mayo N° 375 - 1° Piso Telefax 4662067 - telf:
        4662068
      </Text>
    </View>
  );
};

const FormClone = (props) => {
  const { procedureId, reload, onClose } = props;

  return (
    <>
      <FormDefault
        tittle="Formulario para clonar items"
        fields={[
          {
            label: "Cantidad",
            name: "Nro",
            placeholder: "Cantidad",
            type: "text",
            typeInput: "input",
          },
        ]}
        initialValues={{
          Nro: "1",
        }}
        validationSchema={null}
        onSubmit={async (values) => {
          await requestAuth(
            "post",
            // `/procedureSheet/item/${item.id}/clone?place=down`,
            `/item/procedure/${procedureId}/number/${values.Nro}/clone`,
            values
          )
            .then(() => {
              toast.success("Envio exitoso");
              reload();
              onClose();
            })
            .catch(() => {
              toast.error("Se produjo un error al enviar");
            });
        }}
        buttonName="Clonar"
      />
      <br />
    </>
  );
};

export default CloneItems;
