import { toast } from "react-toastify"
import { requestAuth } from "../../components/services/RequestService"
import { H1 } from "../../components/Text"
import FormDefault from "../../components/Forms/FormDefault"
import { Request } from "../../hooks/Request"
import { validateArray } from "../../utils/validate"

const FormMultiFolder = ({ onClose, setReload, isCopy = false, proc }) => {
  const { data, call } = Request({
    urlApi: `/Adafalabella/fieldsForRepetition`,
  });
  if (isCopy)
    console.log(proc)

  function dataIntialValues(list) {
    let aux = {};
    list?.forEach((element) => {
      aux = {
        ...aux,
        [element.name]:
          (element.initial === "false" ? false : element.initial) || "",
      };
    });
    return aux;
  }

  function handleReaload() {
    call();
    // reload();
  }

  async function onSubmit(values, resetForm, fields) {
    const result = await Promise.all(
      fields.map(async (element) => {
        if (element.type === "selectNewOption") {
          if (values[element.name + "_isNew"]) {
            await handleCreateOption(
              values[element.name] || values[element.name] === 0
                ? values[element.name]
                : "",
              element.url
            );
          }
          return {
            Value:
              values[element.name] || values[element.name] === 0
                ? values[element.name]
                : "",
            ProcedureId: 0,
            FieldId: element.id,
            name: element.name,
            url: element.url,
            type: element.type,
          };
        } else {
          return {
            Value:
              values[element.name] || values[element.name] === 0
                ? values[element.name]
                : "",
            ProcedureId: 0,
            FieldId: element.id,
            name: element.name,
            url: element.url,
            type: element.type,
          };
        }
      })
    );
    await submitDataset(
      {
        companyId: values.companyId,
        procedureTypeId: values.procedureTypeId,
        fromProcedure: values.fromProcedure,
        toProcedure: values.toProcedure
      },
      result.filter((n) => n),
      resetForm,
      values.btnSecondary
    );
  }
  async function handleCreateOption(value, dropdown) {
    await requestAuth("post", "/dropdown/option", {
      Name: value,
      DropdownListLabel: dropdown,
    });
  }
  async function submitDataset(generalValues, values, resetForm, btnSecondary) {
    await requestAuth("post", "/procedure/bulkRegister", { ...generalValues, datasets: values })
      .then(async () => {
        setReload(x => !x)
        onClose();
        resetForm();
        toast.success("Envio exitoso");
      })
      .catch((ex) => {
        console.log(ex)
        toast.error(ex?.response?.data ?? "Error en el envio");
      });
  }
  return (
    <div>
      <H1>Creación de multiples carpetas con valores repetidos</H1>
      {
        validateArray(data) &&
        <FormDefault
          fields={[
            {
              label: "Compañia",
              name: "companyId",
              typeInput: "select",
              urlApi: "/company/getallcompanies",
              value: "id",
              labelOption: "razonSocial",
            },
            {
              label: "Tipo de procedimiento",
              name: "procedureTypeId",
              typeInput: "select",
              urlApi: "/procedureType",
              value: "id",
              labelOption: "name",
            },
            {
              label: "Desde el interno",
              name: "fromProcedure",
              type: "number",
              validate: true

            },
            {
              label: "Hasta el interno",
              name: "toProcedure",
              type: "number",

            },
            ...(data
              .sort((a, b) => a.order - b.order)
              .map((value, index) => ({
                ...value,
                id: 0,
                fieldId: value.id,
                label: value.label,
                labelCustom: (
                  <>
                    <span className="text-green-600">{index + 1}.</span>{" "}
                    {value.label}
                  </>
                ),
                name: value.name,
                validate: false,
                lockEdition: value.lockEdition,
                placeholder: "Complete el campo",
                type: value.type === "fileRead" ? "file" : value.type,
                typeInput: value.type === "fileRead" ? "file" : value.type,
                initial: value.initial ? value.initial : null,
                urlApi: value.url
                  ? value.url.includes("/")
                    ? value.url
                    : `/Dropdown/${value.url}/options`
                  : "",
                urlInitial: value.url,
                value: "name",
                labelOption: "name",
                action: () => console.log(value),
                reload: handleReaload,
              })))
          ]}
          initialValues={{
            companyId: null,
            procedureTypeId: null,
            fromProcedure: "0",
            toProcedure: "0",
            ...dataIntialValues(data)
          }}
          // validationSchema={Yup.object().shape(dataFields(data))}
          onSubmit={async (values, resetForm) =>
            await onSubmit(values, resetForm, data)
          }
          buttonFixed={true}
          buttonSecondary={false}
          buttonName="Registrar"
        />
      }
    </div>
  )
}

export default FormMultiFolder