import React from "react";
import * as Yup from "yup";
import { requestAuth } from "../services/RequestService";
import { toast } from "react-toastify";
import { H1 } from "../Text";
import FormDefault from "../Forms/FormDefault";

const UploadExpenseSheet = ({ procedureId, onClose }) => {
  async function onSubmit(values) {
    let formData = new FormData();
    formData.append("File", values.File);
    formData.append("procedureId", procedureId);
    await requestAuth("post", "/Adafalabella/ReadXlxs", formData)
      .then(() => {
        toast.success("Envio exitoso");
        onClose();
      })
      .catch(() => {
        toast.error("Se produjo un error al subir el archivo");
      });
  }

  return (
    <div>
      <H1>Planilla de gastos principal</H1>
      <FormDefault
        fields={[
          {
            label: "Adjuntar excel",
            name: "File",
            type: "file",
            typeInput: "file",
          },
        ]}
        initialValues={{
          File: "",
        }}
        validationSchema={Yup.object().shape({
          File: Yup.string().required("Este campo es obligatorio"),
        })}
        onSubmit={onSubmit}
        buttonName="Enviar"
      />
    </div>
  );
};

export default UploadExpenseSheet;
