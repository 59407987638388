import { toast } from "react-toastify"
import { requestAuth } from "../../components/services/RequestService"
import { H1 } from "../../components/Text"
import FormDefault from "../../components/Forms/FormDefault"

const FormCopyFolderFiles = ({ onClose, setReload, proc }) => {
  async function onSubmit(values, resetForm) {
    await submitDataset(
      values,
      resetForm,
      values.btnSecondary
    );
  }
  async function submitDataset(values, resetForm, btnSecondary) {
    await requestAuth("post", "/dataSet/copyDocs", values)
      .then(async () => {
        setReload(x => !x)
        onClose();
        resetForm();
        toast.success("Envio exitoso");
      })
      .catch((ex) => {
        console.log(ex)
        toast.error(ex?.response?.data ?? "Error en el envio");
      });
  }
  return (
    <div>
      <H1>Copia de documentos de la carpeta <span className="font-bold text-red-600">{proc?.internCode}</span></H1>
      {
        // validateArray(data) &&
        <FormDefault
          fields={[
            {
              label: "Desde el interno",
              name: "fromProcedure",
              type: "number",
              validate: true

            },
            {
              label: "Hasta el interno",
              name: "toProcedure",
              type: "number",

            },
            {
              label: "Documentos a copiar",
              name: "docs",
              typeInput: "selectGetMultiple",
              urlApi: `/procedure/${proc.id}/allDocs`,
              value: "id",
              labelOption: "label",
            },
          ]}
          initialValues={{
            procedureId: proc.id,
            fromProcedure: "0",
            toProcedure: "0",
            docs: [],
          }}
          // validationSchema={Yup.object().shape(dataFields(data))}
          onSubmit={async (values, resetForm) =>
            await onSubmit(values, resetForm)
          }
          buttonFixed={true}
          buttonSecondary={false}
          buttonName="Registrar"
        />
      }
    </div>
  )
}

export default FormCopyFolderFiles