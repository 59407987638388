import { Request } from "../../hooks/Request";
import logotipo from "../../assets/LogotipoGA.png";
import { Circle } from "../../components/Animation";
import View from "../../components/GeneratePdf/View";
import Text from "../../components/GeneratePdf/Text";
import { Page, Document, PDFViewer, Image } from "@react-pdf/renderer";

const RenderLiquidaciónProforma = (props) => {
  const { procedure } = props;
  const { data, loading } = Request({
    urlApi: `/procedure/${procedure.id || procedure.procedureId}/proform`,
  });

  if (!loading) {
    return <Circle />;
  }
  const MyDoc = (
    <Document>
      <Page size="A4" style={{ padding: "20px" }}>
        <View alignItems="flex-end">
          <View>
            {/* <Image src={logotipo} style={{ width: '250px' }} /> */}
          </View>
          <View width="calc(100% - 250px)" justifyContent="center">
            <Text
              bold
              fontSize="14px"
              color="#ce0707"
              textAlign="center"
              style={{ textDecoration: "underline" }}
            >
              FO. LIQUIDACIÓN - PROFORMA
            </Text>
          </View>
        </View>
        <View style={{ marginTop: "8px" }} justifyContent="space-between">
          <View
            width="60%"
            borderContent
            flexDirection="column"
            style={{ borderRadius: "6px" }}
          >
            <Text bold textAlign="center" border={{ bottom: true }}>
              IMPORTADOR:
            </Text>
            <Text textAlign="center">{procedure.companyName}</Text>
          </View>
          <View width="39%" borderContent style={{ borderRadius: "6px" }}>
            <View width="40%" flexDirection="column" border={{ right: true }}>
              <Text bold textAlign="center" border={{ bottom: true }}>
                LUGAR
              </Text>
              <Text textAlign="center">{data.lugar}</Text>
            </View>
            <View width="20%" flexDirection="column" border={{ right: true }}>
              <Text bold textAlign="center" border={{ bottom: true }}>
                DÍA
              </Text>
              <Text textAlign="center">{data.lugar}</Text>
            </View>
            <View width="20%" flexDirection="column" border={{ right: true }}>
              <Text bold textAlign="center" border={{ bottom: true }}>
                MES
              </Text>
              <Text textAlign="center">{data.lugar}</Text>
            </View>
            <View width="20%" flexDirection="column">
              <Text bold textAlign="center" border={{ bottom: true }}>
                AÑO
              </Text>
              <Text textAlign="center">{data.lugar}</Text>
            </View>
          </View>
        </View>
        <View
          borderContent
          flexDirection="column"
          style={{ marginTop: "8px", borderRadius: "6px" }}
        >
          <View border={{ bottom: true }}>
            <Text bold minWidth="70px" border={{ right: true }}>
              FOB $US:
            </Text>
            <Text minWidth="calc(40% - 70px)">{data.fobSus}</Text>
            <Text bold border={{ right: true, left: true }}>
              ADUANA:
            </Text>
            <Text>{data.aduana}</Text>
          </View>
          <View border={{ bottom: true }}>
            <Text bold minWidth="70px" border={{ right: true }}>
              VALOR CIF $US:
            </Text>
            <Text>{data.cifSus}</Text>
            <Text bold border={{ right: true, left: true }}>
              VALOR CIF BS.:
            </Text>
            <Text>{data.cifBs}</Text>
            <Text bold border={{ right: true, left: true }}>
              T/C:
            </Text>
            <Text>{data.tc}</Text>
          </View>
          <View border={{ bottom: true }}>
            <Text bold minWidth="70px" border={{ right: true }}>
              MERCADERÍA:
            </Text>
            <Text>{data.mercancia}</Text>
          </View>
          <View>
            <Text bold>PROVEEDOR:</Text>
            <Text>{data.proveedor}</Text>
            <Text bold>FACTURA:</Text>
            <Text>{data.factura}</Text>
          </View>
          <View>
            <Text bold>PESO BRUTO:</Text>
            <Text>{data.pesoBruto}</Text>
            <Text bold>PESO NETO:</Text>
            <Text>{data.pesoNeto}</Text>
            <Text bold>BULTOS:</Text>
            <Text>{data.bultos}</Text>
          </View>
          <View>
            <Text bold>FECHA DE LLEGADA:</Text>
            <Text>{data.fechaLlegada}</Text>
            <Text bold>MONEDA:</Text>
            <Text>{data.moneda}</Text>
          </View>
          <View>
            <Text bold>NÚMERO DE PARCIAL:</Text>
            <Text>{data.numeroParcial}</Text>
          </View>
        </View>
        <Text
          bold
          fontSize="12px"
          color="#ce0707"
          style={{ marginTop: "8px", textDecoration: "underline" }}
        >
          FO. LIQUIDACIÓN - PROFORMA
        </Text>
        <View borderContent flexDirection="column" style={{ borderRadius: 6 }}>
          <View border={{ bottom: true }}>
            <View width="25%" flexDirection="column">
              <Text bold textAlign="center" border={{ bottom: true }}>
                REQUISITOS LEGALES
              </Text>
              <Text textAlign="center">FOB:</Text>
              <Text textAlign="center">FLETE I:</Text>
              <Text textAlign="center">FLETE II:</Text>
              <Text textAlign="center">SEGURO:</Text>
              <Text textAlign="center">OTROS GASTOS:</Text>
              <Text textAlign="center">OTRAS EROGACIONES:</Text>
              <Text textAlign="center">GTOS.PUERTO:</Text>
              <Text textAlign="center">VALOR CIF $us.:</Text>
              <Text textAlign="center">VALOR CIF Bs.:</Text>
            </View>
            <View width="25%" flexDirection="column">
              <Text bold textAlign="center" border={{ bottom: true }}>
                LIQUIDACIÓN
              </Text>
              <Text textAlign="center">{data.fob}</Text>
              <Text textAlign="center">{data.fleteI}</Text>
              <Text textAlign="center">{data.fleteII}</Text>
              <Text textAlign="center">{data.seguro}</Text>
              <Text textAlign="center">{data.otrosGastos}</Text>
              <Text textAlign="center">{data.otrasErogaciones}</Text>
              <Text textAlign="center">{data.gastosPortuarios}</Text>
              <Text textAlign="center">{data.cifSus}</Text>
              <Text textAlign="center">{data.cifBs}</Text>
            </View>
            <View width="50%" flexDirection="column" border={{ left: true }}>
              <Text bold textAlign="center" border={{ bottom: true }}>
                IMPUESTOS
              </Text>
              <View>
                <View width="50%" flexDirection="column">
                  <Text>GRAVAMEN ARANCELARIO</Text>
                  <Text>IMPUESTO AL VALOR AGREGADO</Text>
                  <Text>IMPUESTOS GLOBALES</Text>
                </View>
                <View width="50%" flexDirection="column">
                  <Text textAlign="center">{data.gravamenArancelario}</Text>
                  <Text textAlign="center">{data.iva}</Text>
                  <Text textAlign="center">{data.impuestosGlobales}</Text>
                </View>
              </View>
            </View>
          </View>
          <View>
            <View
              width="80%"
              border={{ right: true }}
              justifyContent="flex-end"
            >
              <Text bold>TOTAL (Bs.):</Text>
            </View>
            <Text textAlign="center">{data.total}</Text>
          </View>
        </View>
        <Text
          bold
          fontSize="12px"
          color="#ce0707"
          style={{ marginTop: "8px", textDecoration: "underline" }}
        >
          PROFORMA NRO.
        </Text>
        <View borderContent flexDirection="column" style={{ borderRadius: 6 }}>
          <View border={{ bottom: true }}>
            <View width="80%" flexDirection="column">
              <Text
                bold
                textAlign="center"
                border={{ bottom: true, right: true }}
              >
                DESCRIPCIÓN
              </Text>
              <Text>GRAVAMEN ARANCELARIO</Text>
              <Text>IMPUESTO AL VALOR AGREGADO</Text>
              <Text>IMPUESTOS GLOBALES</Text>
              <Text>GASTOS DE DESPACHO</Text>
              <Text>ALMACENAJE</Text>
              <Text>COMISIÓN AGENCIA</Text>
            </View>
            <View width="20%" flexDirection="column">
              <Text bold textAlign="center" border={{ bottom: true }}>
                GASTOS
              </Text>
              <Text>{data.gravamenArancelario}</Text>
              <Text>{data.iva}</Text>
              <Text>{data.impuestosGlobales}</Text>
              <Text>{data.gastosDespacho}</Text>
              <Text>{data.almacenaje}</Text>
              <Text>{data.comisionAgencia}</Text>
            </View>
          </View>
          <View>
            <View
              width="80%"
              border={{ right: true }}
              justifyContent="flex-end"
            >
              <Text bold>TOTAL PROFORMA Bs.:</Text>
            </View>
            <Text textAlign="center">0,00</Text>
          </View>
        </View>
        <View
          borderContent
          flexDirection="column"
          style={{ borderRadius: "8px", marginTop: "8px" }}
        >
          <View>
            <View
              width="80%"
              border={{ right: true }}
              justifyContent="flex-end"
            >
              <Text color="#ce0707" bold>
                TOTAL $us.:
              </Text>
            </View>
            <Text textAlign="center">0,00</Text>
          </View>
          <Text bold border={{ top: true }}>
            SON: --
          </Text>
        </View>
      </Page>
    </Document>
  );

  return (
    <div>
      <PDFViewer
        style={{
          width: 900,
          height: window.innerHeight - 100,
        }}
        children={MyDoc}
      />
    </div>
  );
};

export default RenderLiquidaciónProforma;
