import FormDefault from "../../../components/Forms/FormDefault";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { requestAuth } from "../../../components/services/RequestService";


const CreateCompany = (props) => {
  const { onClose, reload } = props;
  
  return (
    <div>
      <FormDefault
        tittle="Crear nueva compañía"
        fields={[
          {
            label: "Id de compañia",
            name: "num",
            placeholder: "Introduzca id de compañia (opcional)",
            type: "string",
            typeInput: "input",
          },
          {
            label: "Número de Identificación Tributaria (NIT)",
            name: "nit",
            placeholder: "Introduzca su nùmero de NIT",
            type: "number",
            typeInput: "input",
          },

          {
            label: "Razon social",
            name: "razonSocial",
            placeholder: "Introdusca la razòn social",
            type: "text",
            typeInput: "input",
          },
          {
            label: "Correo electrónico",
            name: "email",
            placeholder: "Introdusca el correo electrónico",
            type: "text",
            typeInput: "input",
          },
        ]}
        initialValues={{
          nit: "",
          razonSocial: "",
        }}
        validationSchema={Yup.object().shape({
          nit: Yup.string().required("Este campo es obligatorio"),
          razonSocial: Yup.string().required("Este campo es obligatorio"),
        })}
        onSubmit={async (values) => {
          await requestAuth("post", "company/Register", values)
            .then(() => {
              reload();
              onClose();
              toast.success("Envio exitoso");
            })
            .catch((response) => {
              toast.error(
                response.response.data
                  ? response.response.data
                  : "Se ha producido un error al enviar"
              );
            });
        }}
        buttonName="Enviar"
      />
    </div>
  );
}

export default CreateCompany;