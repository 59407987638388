import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  Bar,
  getDatasetAtEvent,
  getElementAtEvent,
  getElementsAtEvent,
} from "react-chartjs-2";
import faker from "faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const prepareChartData = (datal, listaMes) => {
  const labels = datal.map((cliente) => cliente.option);
  const datasets = [];

  listaMes.forEach((month) => {
    const dataAux = datal.map((cliente) => {
      const cantidad = cliente.cantidadMes.find(
        (item) => item.nroMes === month.id
      );
      return cantidad.cantidad;
    });

    datasets.push({
      label: month.label,
      data: dataAux,
      backgroundColor: month.color,
    });
  });

  return {
    labels: labels,
    datasets: datasets,
  };
};

const ChartVertical = ({ data, listY, title }) => {
  const chartData = prepareChartData(data, listY);
  return (
    <Bar
      options={{
        plugins: {
          title: {
            display: true,
            text: title,
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
        onClick: function(evt, element) {
          if(element.length > 0) {
              console.log(element,element[0]._datasetInde)
              // you can also get dataset of your selected element
              console.log(data.datasets[element[0]._datasetIndex])
          }}
      }}
      data={chartData}
    />
  );
};

export default ChartVertical;
