import LinkBoxNavBar from "../containers/LinkBoxNavBar";
import { Request } from "../hooks/Request";
import { validateArray } from "../utils/validate";
import { H3 } from "../components/Text";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Recurso_3logo from "../assets/logo45-5.png";
import config from "../config.json";
import { useSocketPending } from "../contexts/SocketPendingContext";
import { requestAuth } from "../components/services/RequestService";

const Navbar = (props) => {
  const { setNavsMenu, navsmenu, path } = props;
  const { rolId, menuName, document } = useParams();
  const { connectionPending, setConnectionId } = useSocketPending();
  const [socketReload, setSocketReload] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { data, loading, call } = Request({
    urlApi: rolId ? `/rol/${rolId}/menu` : null,
  });

  useEffect(() => {
    const connect = connectionPending.connect;
    if (connect) {
      connect.start().then(() => {
        connect.on("ListTypeProcedure", () => {
          console.log("ListTypeProcedure nav");
          setSocketReload((a) => !a);
        });
        requestAuth(
          "post",
          `/auth/SocketConnection/${connect.connection.connectionId}`
        ).then(() => {
          setConnectionId(connect.connection.connectionId);
        });
      });
      // .catch((e) => console.log("Connection failed: ", e));
    }
  }, []);

  useEffect(() => {
    if (
      data !== undefined &&
      data?.menuList &&
      data?.menuList?.length > 0 &&
      document === undefined &&
      !pathname.includes("/generate/dav")
    ) {
      navigate(`/rol/${rolId}/menu/${data?.menuList[0].name}`);
    }
  }, [data, loading]);
  useEffect(() => {
    call();
  }, [rolId]);

  if (!loading) {
    return null;
  } else {
    return (
      <div
        className={`${
          navsmenu
            ? "w-[60px] min-w-[60px] portrait:w-[230px] portrait:min-w-[230px] portrait:hidden"
            : "w-[230px] min-w-[230px] portrait:absolute portrait:z-10"
        } h-full bg-[#618BCC] overflow-y-auto pr-2`}
      >
        <div className="p-3 flex flex-justify-center gap-2 self-center">
          {config.logoUrl && (
            <img
              src={config.logoUrl}
              alt="Logotipo"
              className="w-[70%] mx-auto my-auto rounded-md min-w-[45px]"
            />
          )}
          <img
            src={Recurso_3logo}
            alt="Logotipo"
            className="w-[70%] mx-auto my-auto rounded-md min-w-[45px]"
          />
        </div>
        <button
          className={`text-2xl ${
            navsmenu
              ? "text-center py-4 px-0 portrait:text-right portrait:p-4"
              : "text-left p-4"
          } w-full`}
          onClick={() => setNavsMenu(!navsmenu)}
        >
          <div className={navsmenu ? "" : "rotate-180"}>
            <i className="fas fa-angle-double-right"></i>
          </div>
        </button>
        <nav
          className={`flex flex-col justify-center ${
            navsmenu ? "gap-4" : ""
          } mt-10`}
        >
          {data?.menuList && data?.menuList?.length > 0
            ? data?.menuList.map((url) => {
                return (
                  <LinkBoxNavBar
                    key={url.id}
                    icon={
                      url.icon !== "" && url.icon !== null
                        ? url.icon
                        : "fa-solid fa-building"
                    }
                    to={`/rol/${rolId}/menu/${url.name}`}
                    active={menuName === url.name || path === url.name}
                    setNavsMenu={setNavsMenu}
                    navsmenu={navsmenu}
                  >
                    {navsmenu ? null : url.label}
                  </LinkBoxNavBar>
                );
              })
            : null}
          <ListStep
            {...props}
            navigate={navigate}
            redirect={data?.menuList && data?.menuList?.length > 0}
            socketReload={socketReload}
          />
        </nav>
      </div>
    );
  }
};

const ListStep = (props) => {
  const { setNavsMenu, navsmenu, redirect, navigate, path, socketReload } =
    props;
  const {
    rolId,
    typeId,
    step: numberStep,
    procedureId,
    document,
  } = useParams();

  const { data, loading, call } = Request({
    // urlApi: `/processStep/ForRol/${rolId}`,
    urlApi: `/proceduretype/stepsperrol/${rolId}`,
  });

  useEffect(() => {
    if (data !== undefined && data.length > 0 && !redirect) {
      if (procedureId && document) {
        // navigate(
        //   `/rol/${rolId}/procedure-type/${typeId}/step/${numberStep}/procedure/${procedureId}/generate/${document}`,
        // )
      } else {
        navigate(
          `/rol/${rolId}/procedure-type/${data[0].id}/step/${data[0].processSteps[0].step}`
        );
      }
    }
  }, [data, loading]);

  useEffect(() => {
    call();
  }, [socketReload]);

  if (!loading) {
    return null;
  }

  return (
    <div className="">
      {data && data.length > 0 ? (
        <>
          {data.map((procedureType) => (
            <NavTypeProcedure
              key={procedureType.id}
              procedureType={procedureType}
              setNavsMenu={setNavsMenu}
              navsmenu={navsmenu}
            />
          ))}
          <LinkBoxNavBar
            icon="fas fa-history"
            to={`/rol/${rolId}/history-procedure`}
            active={path === "history-procedure"}
            setNavsMenu={setNavsMenu}
            navsmenu={navsmenu}
          >
            {navsmenu ? null : "Mi historial"}
          </LinkBoxNavBar>
        </>
      ) : null}
    </div>
  );
};

const NavTypeProcedure = ({ setNavsMenu, navsmenu, procedureType }) => {
  const [status, setStatus] = useState(true);
  const { rolId, typeId, step: numberStep } = useParams();

  if (!validateArray(procedureType.processSteps)) {
    return null;
  }

  return (
    <>
      <div
        onClick={() => setStatus(!status)}
        className="bg-white my-2  flex justify-between items-center p-2 pr-3 rounded-r-[30px]"
      >
        <H3>{navsmenu ? "" : procedureType.name}</H3>
        {status ? (
          <i className="fas fa-angle-down"></i>
        ) : (
          <i className="fas fa-angle-up"></i>
        )}
      </div>
      {!validateArray(procedureType.processSteps) || status === false
        ? null
        : procedureType.processSteps
            .sort((a, b) => a.step - b.step)
            .map((step, i) => (
              <LinkBoxNavBar
                key={step.id}
                icon="fas fa-route"
                to={`/rol/${rolId}/procedure-type/${procedureType.id}/step/${step.step}`}
                active={procedureType.id == typeId && step.step == numberStep}
                setNavsMenu={setNavsMenu}
                navsmenu={navsmenu}
                pending={step.pendings}
                step={step.step}
              >
                {navsmenu ? null : step.name}
              </LinkBoxNavBar>
            ))}
    </>
  );
};

export default Navbar;
