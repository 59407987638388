import { toast } from "react-toastify";
import * as Yup from "yup";
import FormDefault from "../../../components/Forms/FormDefault";
import { requestAuth } from "../../../components/services/RequestService";

const EditCompany = (props) => {
  const { companyInfo, onReload } = props;

  return (
    <div>
        <FormDefault
          tittle="Editar datos de la compañía"
          fields={[
            {
              label: "Id de compañia",
              name: "Num",
              placeholder: "Introduzca id de compañia (opcional)",
              type: "string",
              typeInput: "input",
            },
            {
              label: "Número de Identificación Tributaria (NIT)",
              name: "Nit",
              placeholder: "Introduzca su numero NIT",
              type: "number",
              typeInput: "input",
            },
            {
              label: "Razon social",
              name: "RazonSocial",
              placeholder: "Introdusca la Razon Social",
              type: "text",
              typeInput: "input",
            },
            {
              label: "Correo electrónico",
              name: "Email",
              placeholder: "Introdusca el correo electrónico",
              type: "text",
              typeInput: "input",
            },
          ]}
          initialValues={{
            Id: companyInfo?.id || "",
            Nit: companyInfo?.nit || "",
            RazonSocial: companyInfo?.razonSocial || "",
            Email: companyInfo?.email || "",
            Num: companyInfo?.num || "",            
          }}
          validationSchema={Yup.object().shape({
            Nit: Yup.string().required("Este campo es obligatorio"),
            RazonSocial: Yup.string().required("Este campo es obligatorio"),
          })}
          onSubmit={async (values, resetForm) => {
            await requestAuth("put", "company", values)
              .then(() => {
                onReload();
                toast.success("Envio exitoso");                                
              })
              .catch(() => {
                toast.error("Se ha producido un error al enviar");
              });
          }}
          buttonName="Enviar"
        />
    </div>
  )
}

export default EditCompany