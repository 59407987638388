import { ChartVertical } from "./";
import { useState, useEffect } from "react";
import { Request } from "../../hooks/Request";
import { monthList } from "../../utils/constant";
import { Circle } from "../../components/Animation";
import { Button } from "../../components/Buttons";
import { validateArray } from "../../utils/validate";

import * as dayjs from "dayjs";
import Select from "react-select";
import es from "date-fns/locale/es";
import "react-day-picker/src/style.css";
import { DayPicker } from "react-day-picker";

const ChartContainer = (props) => {
  const { title, urlApi, filters } = props;
  const [params, setParams] = useState({});

  const { data, loading, call } = Request({
    urlApi: `${urlApi}${handleFilters(params)}`,
  });

  function handleFilters(filters) {
    let aux = "";
    if (filters) {
      for (let key in filters) {
        if (filters[key]) {
          aux = aux + `&${key}=${filters[key]}`;
        }
      }
    }
    return `?DateQuery=a2FechaDeAceptación${aux}`;
  }

  useEffect(() => {
    if (loading) {
      call();
    }
  }, [urlApi]);

  return (
    <div className="">
      <ChartFilters
        filters={filters}
        params={params}
        setParams={setParams}
        call={call}
      />
      <br />
      {loading ? (
        <div className="w-[80%] m-auto">
          <ChartVertical data={data} listY={monthList} title={title} />
        </div>
      ) : (
        <Circle />
      )}
    </div>
  );
};

const ChartFilters = ({ filters, params, setParams, call }) => {
  function handleReload() {
    call();
  }

  return (
    <div className="flex gap-2 items-center">
      {filters && filters.length > 0
        ? filters.map((filter, index) =>
            filter.type === "rangeDate" ? (
              <RangeDate
                key={index}
                filter={filter}
                params={params}
                setParams={setParams}
              />
            ) : (
              <Filerts
                key={index}
                filter={filter}
                params={params}
                setParams={setParams}
              />
            )
          )
        : null}
      <Button onClick={handleReload} className="p-2 text-base">
        Aplicar filtros
      </Button>
    </div>
  );
};

const RangeDate = ({ params, setParams }) => {
  const [view, setView] = useState(false);
  const [selectedDay, setSelectedDay] = useState();

  useEffect(() => {
    setParams((a = {}) => ({
      ...a,
      initial:
        selectedDay !== undefined && selectedDay
          ? selectedDay.from !== undefined && selectedDay.from
            ? dayjs(selectedDay.from).format("YYYY-MM-DDTHH:mm:ss")
            : ""
          : "",
      final:
        selectedDay !== undefined && selectedDay
          ? selectedDay.to !== undefined && selectedDay.to
            ? dayjs(selectedDay.to).format("YYYY-MM-DDTHH:mm:ss")
            : ""
          : "",
    }));
  }, [selectedDay]);

  return (
    <div className="static">
      <div
        style={{
          background: params.initial ? "#f97316" : "#94a3b842",
          color: params.initial ? "#e2e8f0" : "gray",
          border: params.initial ? "1px solid #f97316" : "1px solid #94a3b842",
        }}
        className={`min-w-[330px] h-10 rounded-lg hover:text-black selected portrait:w-[100%] portrait:m-0`}
        onClick={() => setView(!view)}
      >
        <div className="pt-3">
          {selectedDay === undefined && !selectedDay ? (
            "Elija una fecha"
          ) : (
            <>
              {selectedDay.from
                ? dayjs(selectedDay.from).format("DD/MM/YYYY")
                : ""}
              {" - "}
              {selectedDay.to ? dayjs(selectedDay.to).format("DD/MM/YYYY") : ""}
            </>
          )}
        </div>
      </div>
      {view && (
        <>
          <div
            onClick={() => setView(!view)}
            className="absolute z-10 w-screen h-screen top-0 left-0"
          ></div>
          <div className="absolute z-10 bg-[#fff] shadow-lg w-fit mt-2 rounded-md">
            <DayPicker
              locale={es}
              mode="range"
              selected={selectedDay}
              onSelect={setSelectedDay}
            />
          </div>
        </>
      )}
    </div>
  );
};

const Filerts = ({ filter, params, setParams }) => {
  const { data, loading, error } = Request({
    urlApi: filter.url ? `${filter.url}` : null,
  });

  function handleOnChange(e) {
    setParams((a) => ({ ...a, [filter.name]: e.value }));
  }

  function handleOption() {
    if (filter.url) {
      if (validateArray(data)) {
        return data.map((option) => ({
          value: option[filter.valueOption ?? "name"],
          label: option[filter.labelOption ?? "label"],
        }));
      }
      return [];
    } else {
      return filter.options.map((option) => ({
        value: option[filter.valueOption ?? "name"],
        label: option[filter.labelOption ?? "label"],
      }));
    }
  }

  return error && filter.url
    ? null
    : loading && (
        <div className="min-w-[150px]">
          <Select
            isSearchable={true}
            onChange={handleOnChange}
            styles={{
              placeholder: (base) => ({
                ...base,
                color:
                  !params[filter.name] &&
                  (params[filter.name] === filter.defaultValue ||
                    params[filter.name] === "")
                    ? "gray"
                    : "white",
              }),
              singleValue: (base) => ({
                ...base,
                color:
                  !params[filter.name] &&
                  (params[filter.name] === filter.defaultValue ||
                    params[filter.name] === "")
                    ? "gray"
                    : "white",
                // color: "white",
                // color: "gray",
              }),
              control: (base) => ({
                ...base,
                background:
                  !params[filter.name] &&
                  (params[filter.name] === filter.defaultValue ||
                    params[filter.name] === "")
                    ? "#94a3b842"
                    : "#f97316",
                color:
                  !params[filter.name] &&
                  (params[filter.name] === filter.defaultValue ||
                    params[filter.name] === "")
                    ? "gray"
                    : "white",
                border:
                  !params[filter.name] &&
                  (params[filter.name] === filter.defaultValue ||
                    params[filter.name] === "")
                    ? "1px solid #94a3b842"
                    : "1px solid #f97316",
                borderRadius: "10px",
              }),
            }}
            placeholder={filter.label ?? "Seleccionar..."}
            isOptionSelected={false}
            isLoading={filter.url ? !loading : false}
            options={[
              {
                label: filter.default
                  ? filter.default
                  : filter.label
                  ? filter.label
                  : "Todos",
                value: filter.defaultValue ?? "",
              },
              ...handleOption(),
            ]}
          />
          {/* <select
            name="SearchSelect"
            className="bg-[#f97316] h-10 rounded-lg text-[#e2e8f0] hover:text-black selected portrait:w-[100%]"
            onChange={handleOnChange}
            defaultValue
          >
            <option value="">
              {filter.default ? filter.default : 'Seleccionar...'}
            </option>
            {filter.url
              ? data.map((value, key) => (
                  <option
                    key={key}
                    value={
                      value[filter.valueOption ? filter.valueOption : 'name']
                    }
                  >
                    {value[filter.labelOption ? filter.labelOption : 'label']}
                  </option>
                ))
              : filter.options.map((value, key) => (
                  <option
                    key={key}
                    value={
                      value[filter.valueOption ? filter.valueOption : 'name']
                    }
                  >
                    {value[filter.labelOption ? filter.labelOption : 'label']}
                  </option>
                ))}
          </select> */}
        </div>
      );
};
export default ChartContainer;
