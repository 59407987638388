import { toast } from "react-toastify"
import { requestAuth } from "../../components/services/RequestService"
import { H1 } from "../../components/Text"
import { Request } from "../../hooks/Request"
import DragList from "../../components/Drags/DragList"

const FormOrderDocs = ({ onClose, setReload, proc }) => {
  const { data, loading, call } = Request({
    urlApi: `/procedure/${proc.id || proc.procedureId}/allGeneralDocs`,
  })

  return (
    <div>
      <DragList
        tittle={<H1>Documentos de la carpeta <span className="font-bold text-red-600">{proc?.internCode}</span></H1>}
        data={data}
        orderFieldsActive={true}
        onSaveChange={(dataList) => {
          const newDocs = dataList?.map((doc, index) => ({
            fileStateId: doc.fileStateId,
            dataSetId: doc.dataSetId,
            order: index + 1,
          }));
          requestAuth("put", `procedure/OrderDocument`, newDocs)
            .then(() => {
              call();
              onClose();
              toast.success("Envio exitoso");
            })
            .catch(() => {
              toast.error("Se produjo un error al realizar los cambios");
            });
        }}
      />
    </div>
  )
}

export default FormOrderDocs