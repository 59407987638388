import CallServiceTable from "../../../components/Tables/CallServiceTable";
import Button from "../../../components/Buttons/Button";
import { useModal } from "../../../contexts/ModalContext";
import { useDialog } from "../../../contexts/DialogContext";
import CompanyDetail from "../../../containers/menu/manageCompany/CompanyDetail";
import CreateCompany from "../../../containers/menu/manageCompany/CreateCompany";
import ImportClient from "../../../containers/menu/manageCompany/ImportClient";
import RenderPDFClient from "../../../containers/menu/manageCompany/RenderPDFClient";

const ManageCompany = () => {
  const { openModal, onClose } = useModal();
  const { openDialog, dialogClose } = useDialog();

  function handleImport(reload) {
    openModal(<ImportClient reload={reload} onClose={onClose}/>);
  }

  function handleNewCompany(reload) {
    openModal(<CreateCompany  reload={reload} onClose={onClose}/>)
  }  

  function handleGetCompany(company, reload) {
    openModal(<CompanyDetail companyID={company.id} reload={reload} onClose={onClose} />)    
  }

  
  function handleDocumentPdf(data) {
    openDialog(<RenderPDFClient company={data} dialogClose={dialogClose} />);
  }


  return (
    <CallServiceTable
        onClick={handleGetCompany}
        urlApi="/company"
        component={({ reload }) => {
          return (
            <>
              <Button onClick={() => handleImport(reload)}>
                Importar <i className="fas fa-cloud-download-alt"></i>
              </Button>
              <Button onClick={() => handleNewCompany(reload)}>
                Crear compañía <i className="fa-solid fa-plus"></i>
              </Button>
            </>
          );
        }}
        header={[
          {
            name: "razonSocial",
            label: "Compañía",
            filter: true,
          },
          {
            name: "nit",
            label: "NIT",
            filter: true,
          },
          {
            name: "status",
            label: "Estado",
            filter: false,
          },
          {
            name: "Reports",
            type: "action",
            label: "Reporte",
            actions: [
              {
                label: "Imprimir datos del cliente",
                icon: "fas fa-print",
                action: handleDocumentPdf,
                color: "text-[#1d4ed8] pl-3",
              },
            ],
          },
        ]}
        addUrl={`userId=${localStorage.getItem("id")}`}
        showTable={false}
      />
  )
}

export default ManageCompany